import { GoogleMap } from '@config/settings'
import { colors } from '@src/theme/colors'
import React from 'react'
import ReactGoogleMap from 'react-google-map'
import GoogleMapLoader from 'react-google-maps-loader'

// import styles from "./index.css"

// https://developers.google.com/maps/documentation/javascript/markers
const originalIconSize = {
  width: 1000,
  height: 1600,
}
// const iconScale = 0.02
const pinIcon = {
  path:
    'M504.886,0 C593.787,1.445 681.067,25.065 758.243,68.643 C828.564,109.307 883.027,163.625 924.42,227.641 C967.627,294.456 997.382,373.392 999.875,476.353 C1001.156,529.103 992.423,577.954 980.116,618.474 C967.666,659.489 947.638,693.773 929.818,730.394 C895.028,801.88 851.419,867.381 807.648,932.915 C677.282,1128.128 554.922,1327.206 501.333,1600 C464.292,1420.197 398.984,1270.563 319.881,1131.889 C261.206,1029.024 193.235,934.077 130.344,834.321 C109.35,801.022 91.231,765.843 71.058,731.283 C30.72,662.18 -1.984,582.06 0.094,478.13 C2.124,376.586 31.824,295.13 74.652,228.53 C145.09,118.99 263.077,29.179 421.388,5.577 C441.249,2.614 461.289,0.832 481.366,0.2 C489.202,-0.046 497.046,0.067 504.886,0 z M500.001,303.759 C403.255,303.759 324.827,381.312 324.826,476.979 C324.827,572.646 403.255,650.2 500.001,650.2 C596.748,650.2 675.176,572.646 675.176,476.979 C675.176,381.312 596.748,303.759 500.001,303.759 z',
  fillOpacity: 1,
  scale: 0.02,
  fillColor: colors.mapPin,
}
const pinIconHover = {
  fillColor: colors.green,
}

const Map: React.SFC<{
  center?: any
  googleMaps: any
  title?: string
  zoom?: number
}> = ({ googleMaps, ...props }) => {
  const iconMarker = {
    ...pinIcon,
    size: new googleMaps.Size(originalIconSize.width, originalIconSize.height),
    origin: new googleMaps.Point(0, 0),
    anchor: new googleMaps.Point(originalIconSize.width / 2, originalIconSize.height),
    // scaledSize: new googleMaps.Size(20, 32)
  }
  const iconMarkerHover = {
    ...pinIcon,
    ...pinIconHover,
    size: new googleMaps.Size(originalIconSize.width, originalIconSize.height),
    origin: new googleMaps.Point(0, 0),
    anchor: new googleMaps.Point(originalIconSize.width / 2, originalIconSize.height),
    // scaledSize: new googleMaps.Size(20, 32)
  }

  return (
    // ReactGoogleMap component has a 100% height style.
    // You have to set the DOM parent height.
    // So you can perfectly handle responsive with different heights.
    <ReactGoogleMap
      googleMaps={googleMaps}
      // You can add and remove coordinates on the fly.
      // The map will rerender new markers and remove the old ones.
      coordinates={[
        {
          title: props.title,
          icon: iconMarker,
          position: {
            lat: props.center[0],
            lng: props.center[1],
          },
          onLoaded: (googleMaps: any, map: any, marker: any) => {
            // Set Marker animation
            marker.setAnimation(googleMaps.Animation.BOUNCE)

            // Define Marker InfoWindow
            const infoWindow = new googleMaps.InfoWindow({
              content: `
                <div style="text-align: center;">
                  <h3>${props.title}<h3>
                  <div>
                    We Are Here!
                  </div>
                </div>
              `,
            })

            // Open InfoWindow when Marker is clicked
            googleMaps.event.addListener(marker, 'click', () => {
              infoWindow.open(map, marker)
            })

            // Change icon when Marker is hovered
            googleMaps.event.addListener(marker, 'mouseover', () => {
              marker.setIcon(iconMarkerHover)
            })

            googleMaps.event.addListener(marker, 'mouseout', () => {
              marker.setIcon(iconMarker)
            })

            // Open InfoWindow directly
            infoWindow.open(map, marker)
          },
        },
      ]}
      center={{ lat: props.center[0], lng: props.center[1] }}
      zoom={props.zoom}
      onLoaded={(googleMaps, map) => {
        // map.setMapTypeId(googleMaps.MapTypeId.SATELLITE)
        map.setMapTypeId(googleMaps.MapTypeId.ROADMAP)
        map.setOptions({
          styles: mapOptions,
        })
      }}
    />
  )
}

const mapOptions = [
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

export default (props) => (
  <GoogleMapLoader
    params={{
      libraries: 'places',
      key: GoogleMap.API_KEY,
    }}
    render={(googleMaps) =>
      googleMaps ? <Map googleMaps={googleMaps} {...props} /> : <div>Loading...</div>
    }
  />
)

// import GoogleMapReact from 'google-map-react';
// // import MyGreatPlaceWithHover from '../../atoms/GoogleMapPin';

// import { K_SIZE } from '../../atoms/GoogleMapPin/styles.js';
// import marker from './map-pin.svg';

// const MapMarkerWidth = 16
// const styles = {
//   Marker: {
//     width: MapMarkerWidth,
//     height: MapMarkerWidth * 1.6,
//     position: 'absolute',
//     top: -MapMarkerWidth * 1.6,
//     left: -MapMarkerWidth / 2,
//     bosShadow: '0 0 2px #000'
//   }
// }

// const Marker = () => (
//   <div style={ styles.Marker }>
//     <img src={marker} style={{
//       width: '100%',
//       // height: '100%'
//     }} />
//   </div>
// )

// // const mapOptions = {
// //   style: {
// //     feature: "landscape",
// //     element: "labels",
// //     visibility: "off",
// //   },
// //   style: {
// //     feature: "poi",
// //     element: "labels",
// //     visibility: "off",
// //   },
// //   style: {
// //     feature: "road",
// //     element: "labels",
// //     visibility: "off",
// //   }
// // }

// export default class SimpleHoverMapPage extends PureComponent {
//   static propTypes = {
//     center: PropTypes.array,
//     zoom: PropTypes.number,
//     // greatPlaceCoords: PropTypes.any
//   };

//   static defaultProps = {
//     center: [59.938043, 30.337157],
//     zoom: 9,
//     // greatPlaceCoords: { lat: 59.724465, lng: 30.080121 }
//   };

//   constructor(props) {
//     super(props);
//   }

//   render() {
//     return (
//       <GoogleMapReact
//         // apiKey={YOUR_GOOGLE_MAP_API_KEY} // set if you need stats etc ...
//         bootstrapURLKeys={{style: mapOptions}}
//         center={this.props.center}
//         zoom={this.props.zoom}
//         optios={this.mapOptions}
//         // instead of css hover (which sometimes is bad for map markers) (bad means inability to hover on markers placed under other markers)
//         // you can use internal GoogleMap component hover algorithm
//         // hover algorithm explained at x_distance_hover example
//         // hoverDistance={K_SIZE / 2}
//       >
//         <Marker lat={this.props.center[0]} lng={this.props.center[1]} />
//       </GoogleMapReact>
//         // <MyGreatPlaceWithHover lat={this.props.center.lat} lng={this.props.center.lng} text={'A'} /* Kreyser Avrora */ />
//         // <MyGreatPlaceWithHover {...this.props.greatPlaceCoords} text={'B'} /* road circle */ />
//     );
//   }
// }
